import React, { useEffect, useState } from 'react';

function AudioRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [socket, setSocket] = useState(null);


  useEffect(() => {
    
      const webSocket = new WebSocket(`wss://${window.location.host}/ws/audio`);
      webSocket.binaryType = 'arraybuffer';
      setSocket(webSocket);

      webSocket.onopen = () => {
        console.log('WebSocket connection established');
      };

      const audioContext = new (window.AudioContext || window.webkitAudioContext)();

      webSocket.onmessage = async (event) => {
        console.log('Received message:', event.data);
        let arrayBuffer = event.data;
          // Decode the audio data
        audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
            // Create a buffer source
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
             // Connect the source to the audio context destination
            source.connect(audioContext.destination);
             // Play the audio
            source.start(0);
        }, (error) => {
            console.error("Error decoding audio data:", error);
        });
      };


      webSocket.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      webSocket.onclose = () => {
        console.log('WebSocket connection closed');
      };
    

  }, []);

  // Start recording audio
  const startRecording = async () => {



    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const mimeType = 'audio/webm';  // You can change this to 'audio/wav', 'audio/ogg', etc.

    const recorder = new MediaRecorder(stream, { mimeType: mimeType});
    recorder.ondataavailable = (event) => {
      setAudioChunks((prevChunks) => [...prevChunks, event.data]);
      if (socket && socket.readyState === WebSocket.OPEN) {
        console.log(event.data);
        socket.send(event.data); // Send audio chunk to WebSocket server
      }
    };

    recorder.start(100);
    setMediaRecorder(recorder);
    setIsRecording(true);
  };

  // Stop recording
  const stopRecording = () => {
    mediaRecorder.stop();
    //socket.close();
    //setSocket(null);
    const emptyBuffer = new ArrayBuffer(0);
    socket.send(emptyBuffer);

    console.log('Web socket has been close and media recorder has stopted.');
    setIsRecording(false);
  };

  return (
    <div>
      <button className="btn btn-custom btn-lg page-scroll" onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? 'Stop' : 'Start'}
      </button>
    </div>
  );
}

export default AudioRecorder;
